<template>
  <div class="row">
    <template v-if="!loading">
      <div class="col-md-9">
        <h2 class="h4">{{ topic.title }}</h2>
        <div v-html="topic.content" class="mb-3"></div>
        <div v-if="topic.attachment == 'image'" class="mb-4 text-center">
          <img :src="topic.image_url" :alt="topic.title">
        </div>
        <div v-if="topic.attachment == 'video'" class="mb-4">
          <div class="vimeo-player">
            <vimeo-player
              v-if="! loading"
              class="vimeo-player"
              ref="player"
              :video-url="topic.video_url"
              player-width="auto"
              player-height="auto"
              :options="{ responsive: true }"
            />
          </div>
        </div>
        <chat-box
          type="discussions"
          role="admin"
          :id="$route.params.id"
          :title="topic.title"
          @updated="updateComments"
          :open="topic.status == 'open'"
        />
      </div>
      <div class="col-md-3">
        <h4 class="mb-0">Details</h4>
        <div class="card">
          <div class="px-2 py-2">
            <p class="mb-0">
              Author: <strong>{{ topic.author.name }}</strong>
            </p>
            <p class="mb-0">
              Date: <strong>{{ $localizer.parse(topic.date) }}</strong>
            </p>
            <p class="mb-0">
              Comments: <strong>{{ current_comments }}</strong>
            </p>
            <p class="mb-2">
              Status: <strong>{{ topic.status }}</strong>
            </p>
            <p>
              <router-link :to="{ name: 'admin.discussions.edit' }" class="btn btn-info btn-sm"><i class="lni lni-pencil mr-2"></i>Edit</router-link>
            </p>
            <p>
              <a href="#" class="btn btn-primary btn-sm mt-2" @click.prevent="toggleStatus">{{ topic.status == 'open' ? 'Close' : 'Open'}}</a>
              <a href="#" class="btn btn-danger btn-sm float-right" @click.prevent="deleteVideo"><i class="lni lni-trash-can"></i></a>
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="loading">
      <div class="col-md-9">
        <div class="text-center">
          <div><img src="/logo.png" alt="logo" style="margin-top: 5rem" /></div>
          <div>
            <img src="/loader.gif" alt="loading" style="width: 100px" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      topic: { author: {} },
      current_comments: 0,
      users: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true
      this.$axios
        .get(`/api/v1/admin/discussions/${this.$route.params.id}`)
        .then((response) => {
          this.topic = response.data.topic;
          this.users = response.data.users;
          this.loading = false;
        });
    },

    updateComments(total) {
      this.current_comments = total;
    },

    toggleStatus() {
      this.$axios.put(`/api/v1/admin/discussions/${this.$route.params.id}`).then(() => {
        this.fetch()
      })
    },

    deleteVideo() {
      this.$axios.delete(`/api/v1/admin/discussions/${this.$route.params.id}`).then(() => {
        this.$router.push({ name: 'admin.discussions.index' })
      })
    }
  },
};
</script>
